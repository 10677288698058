import React, { createContext, useState } from "react"

const AlertContext = createContext({})

/**
 * Context for the admin area
 */
export const AlertProvider = ({ children }) => {
    const [snackbarMessage, setSnackbarMessage] = useState(undefined)

    const addSnackbarMessage = (message, severity = "info") => {
        setSnackbarMessage({ message, severity })
    }

    const clearSnackbarMessage = () => {
        setSnackbarMessage(undefined)
    }

    return (
        <AlertContext.Provider
            value={{
                snackbarMessage,
                addSnackbarMessage,
                clearSnackbarMessage,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

export default AlertContext
