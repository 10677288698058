import React from "react"

function ErrorBlock({ error = undefined, errorTitle = "Error" }) {
    error ??= "Opps, something went wrong!"

    return (
        <div className='flex-column-center error-full-notice width-100'>
            <p className='flex-center-item'>{errorTitle}</p>
            <span className='flex-center-item sub-title'>{error}</span>
        </div>
    )
}

export default ErrorBlock
