import React from "react"
//import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import { default as FlagCircleIcon } from "@mui/icons-material/Flag"

import { dangerColor, warningColor, primaryColor, secondaryColor, successColor } from "../Theme"

/**
 * Returns a colored flag matchign the pased icon id
 * 0 = no flag
 * @param {{flag: number|undefined, style: {}|undefined, showIfZero: boolean}} param0
 */
function FlagIcon({ flag = undefined, style = undefined, showIfZero = false, fontSize = undefined }) {
    if (!showIfZero && !flag) return null

    const styles = { ...style, color: flagIdToColor(flag) }
    return <FlagCircleIcon style={styles} fontSize={fontSize} />
}

/**
 * Converts the flag if To a hex value
 * @param {number} id
 */
function flagIdToColor(id = 0) {
    switch (id) {
        case 1:
            return primaryColor
        case 2:
            return secondaryColor
        case 3:
            return successColor
        case 4:
            return warningColor
        case 5:
            return dangerColor
        default:
            return "currentColor"
    }
}

/**
 * Converts the flag to a description
 * @param {number} id
 */
export function flagIdToLabel(id = 0) {
    switch (id) {
        case 1:
            return "Notice"
        case 2:
            return "Important"
        case 3:
            return "Tip"
        case 4:
            return "Warning"
        case 5:
            return "Caution"
        default:
            return "Unflagged"
    }
}

export default FlagIcon
export const maxFlagIcons = 5
